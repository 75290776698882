export const extractAccountIdFromSrn = (srn: string | undefined) => {
  if (srn === undefined) {
    return "";
  }
  const parts = srn.split(":");

  if (parts.length >= 4) {
    return parts[2];
  } else {
    return "";
  }
};
