import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import "../../../assets/style.css";
import App from "./App";

const rootElement = document.getElementById("root");

if (rootElement) {
  const root = createRoot(rootElement);

  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  );
} else {
  throw new Error('there is no "root" element in the DOM');
}
