import { useAuth } from "oidc-react";
import { useEffect, useRef } from "react";

export const PendoInitializer = () => {
  const auth = useAuth();
  const accountEmail = auth.userData?.profile.email;
  const accountName = auth.userData?.profile.an as string;

  const pendoInitialized = useRef<boolean>(false);
  const isPendoInitialized = pendoInitialized.current === true;

  useEffect(() => {
    if (isPendoInitialized || !accountEmail || !accountName) {
      return;
    }

    pendo.initialize({
      visitor: {
        id: accountEmail,
        email: accountEmail,
        full_name: accountName,
      },
    });
    pendoInitialized.current = true;
  }, [accountEmail, accountName, isPendoInitialized]);

  return null;
};
