import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AuthProvider } from "oidc-react";
import { BrowserRouter as Router } from "react-router-dom";

import { Environment, FeatureFlagsManagementProvider } from "@web/common";
import { CustomerSupportWidget } from "@web/ui";

import { AuthCheckpoint } from "./AuthCheckpoint";
import { CatalogProvider } from "./CatalogContext";
import { PendoInitializer } from "./PendoInitializer";
import { queryClient } from "./QueryClientCreator";
import { SystemMessagesProvider } from "./SystemMessages";
import { UnleashContextSetup } from "./UnleashContextSetup";

const UNLEASH_APP_NAME = "catalog-management";
const UNLEASH_REFRESH_INTERVAL = 60;

const VITE_STS_CLIENT_ID = import.meta.env.VITE_STS_CLIENT_ID as string;
const VITE_STS_DOMAIN = import.meta.env.VITE_STS_DOMAIN as string;
const VITE_UNLEASH_PROXY_URL = import.meta.env.VITE_UNLEASH_PROXY_URL as string;
const VITE_UNLEASH_PROXY_CLIENT_KEY = import.meta.env.VITE_UNLEASH_PROXY_CLIENT_KEY as string;
const VITE_UNLEASH_APP_ENVIRONMENT = import.meta.env.VITE_UNLEASH_APP_ENVIRONMENT as Environment;

const oidcConfig = {
  onSignIn: async () => {
    window.location.hash = "/";
  },
  authority: `${VITE_STS_DOMAIN}`,
  clientId: `${VITE_STS_CLIENT_ID}`,
  redirectUri: window.location.origin,
};

interface AppProvidersProps {
  children: React.ReactNode;
}

const AppProviders: React.FC<AppProvidersProps> = ({ children }) => {
  return (
    <AuthProvider {...oidcConfig}>
      <CustomerSupportWidget />
      <QueryClientProvider client={queryClient}>
        <FeatureFlagsManagementProvider
          environment={VITE_UNLEASH_APP_ENVIRONMENT}
          unleashProxyUrl={VITE_UNLEASH_PROXY_URL}
          unleashAppName={UNLEASH_APP_NAME}
          unleashProxyClientKey={VITE_UNLEASH_PROXY_CLIENT_KEY}
          unleashRefreshInterval={UNLEASH_REFRESH_INTERVAL}
        >
          <CatalogProvider>
            <Router>
              <ReactQueryDevtools initialIsOpen={false} />
              <SystemMessagesProvider>
                <AuthCheckpoint>
                  <PendoInitializer />
                  <UnleashContextSetup />
                  <>{children}</>
                </AuthCheckpoint>
              </SystemMessagesProvider>
            </Router>
          </CatalogProvider>
        </FeatureFlagsManagementProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
};

export default AppProviders;
