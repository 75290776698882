/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import { ApiRequestOptions } from "../core/ApiRequestOptions";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { CategoryResultResponse } from "../models/CategoryResultResponse";
import type { CategorySearchRequest } from "../models/CategorySearchRequest";
import type { ProductResultResponse } from "../models/ProductResultResponse";
import type { ProductSearchRequest } from "../models/ProductSearchRequest";

export class SearchService {
  /**
   * Search products
   * Search for products based on a structured request and return paginated results.
   * @returns ProductResultResponse OK
   * @throws ApiError
   */
  public static products(
    {
      requestBody,
    }: {
      requestBody: ProductSearchRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<ProductResultResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/search/products",
      body: requestBody,
      mediaType: "application/json",
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * Search categories
   * Search for categories based on a structured request and return paginated results.
   * @returns CategoryResultResponse OK
   * @throws ApiError
   */
  public static categories(
    {
      requestBody,
    }: {
      requestBody: CategorySearchRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<CategoryResultResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/search/categories",
      body: requestBody,
      mediaType: "application/json",
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }
}
