import { QuestionMarkCircleIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";

import { RegularAnchor } from "@web/ui";

import { RoutesConfig } from "src/config/routes";

import { BadgeLink } from "../BadgeLink";
import Logo from "../icons/s2s-logo.png";

export const Sidebar = () => {
  const navigate = useNavigate();

  return (
    <div
      className="fixed top-0 left-0 h-full bg-neutral_900 w-[300px] min-w-[270px] flex flex-col justify-between pt-5 items-center z-30"
      data-testid="sidebar"
    >
      <div className="flex flex-col items-center w-full">
        <img
          src={Logo}
          className="w-[60%] mb-8 cursor-pointer"
          alt="Pelican icon"
          onClick={() => navigate("/")}
          data-testid="sidebarLogo"
        />
        <div className="px-4 w-full" data-testid="sidebarNavigation">
          <BadgeLink
            text="Dashboard"
            pathname={RoutesConfig.dashboard}
            search="dashboard"
            data-testid="sidebarNavigation_dashboard"
          />
          <BadgeLink
            text="Catalogs"
            pathname={RoutesConfig.catalogs}
            search="catalogs"
            data-testid="sidebarNavigation_catalogs"
          />
        </div>
      </div>
      <div className="mb-4 w-full px-5 pb-3">
        <RegularAnchor
          variant="primary"
          width="container"
          LeadingIcon={QuestionMarkCircleIcon}
          size="small"
          target="_blank"
          label="Help & resources"
          href="https://source2sea.notion.site/catalog-management-portal-user-guide"
          data-testid="sidebar_helpAndResourcesButton"
        />
      </div>
    </div>
  );
};
