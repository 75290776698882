/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import { ApiRequestOptions } from "../core/ApiRequestOptions";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { DocumentsResponse } from "../models/DocumentsResponse";
import type { ImageMetricsResult } from "../models/ImageMetricsResult";
import type { Pageable } from "../models/Pageable";
import type { ProductSearchResult } from "../models/ProductSearchResult";
import type { ProductsResult } from "../models/ProductsResult";

export class ProductService {
  /**
   * @deprecated
   * Full text product search
   * Doing a fulltext search on product title, description, category, supplier article code, where availability
   * parameters are mandatory (supplierCatalogId, locode, fleetSrn list). Before it searches for anything it
   * replaces all special characters with whitespace, then use each of the terms as keywords.
   * Depending on which field it is either searched as a keyword or full text indexed string with synonyms.
   *
   * @returns DocumentsResponse OK
   * @throws ApiError
   */
  public static buyerSearch(
    {
      curatedCatalogId,
      locode,
      search,
      fleetSrn,
      pageable,
      caller,
      sortField = "_score",
      sortOrder = "desc",
    }: {
      /**
       * Particular Curated catalog ID. Mandatory parameter
       */
      curatedCatalogId: string;
      /**
       * Port UN Locode
       */
      locode: string;
      /**
       * Searched phrase
       */
      search: string;
      fleetSrn: Array<string>;
      pageable: Pageable;
      /**
       * Who called the search service
       */
      caller?: string;
      sortField?: string;
      sortOrder?: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<DocumentsResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/catalogs/search/{curatedCatalogId}/{locode}",
      path: {
        curatedCatalogId: curatedCatalogId,
        locode: locode,
      },
      query: {
        search: search,
        fleetSrn: fleetSrn,
        caller: caller,
        sortField: sortField,
        sortOrder: sortOrder,
        pageable: pageable,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * Get image metrics
   * Get image metrics(available, missing, broken) for all products in particular location and target as a supplier.
   * Will not list any products that do not have the price set for a particular target/location supplier.
   *
   * @returns ImageMetricsResult OK
   * @throws ApiError
   */
  public static getImageMetrics(
    {
      catalogId,
      targetSrn,
      locode,
    }: {
      /**
       * Particular catalog ID. Mandatory parameter
       */
      catalogId: string;
      /**
       * Srn of a target for the price. Usually it is AgreementSrn
       */
      targetSrn?: string;
      /**
       * Port UN Locode
       */
      locode?: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<ImageMetricsResult> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/catalogs/search/{catalogId}/products/images",
      path: {
        catalogId: catalogId,
      },
      query: {
        targetSrn: targetSrn,
        locode: locode,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * Get products list with prices
   * Getting the product list together with prices for particular location and target as a supplier.
   * Will not list any products that do not have the price set for a particular target/location supplier.
   *
   * @returns ProductsResult OK
   * @throws ApiError
   */
  public static getProducts(
    {
      catalogId,
      targetSrn,
      locode,
      page = 1,
      size = 10,
      imageQualityFilter = "ALL",
    }: {
      /**
       * Particular catalog ID. Mandatory parameter
       */
      catalogId: string;
      /**
       * Srn of a target for the price. Usually it is AgreementSrn
       */
      targetSrn: string;
      /**
       * Port UN Locode
       */
      locode: string;
      page?: number;
      size?: number;
      imageQualityFilter?: "EXISTING" | "MISSING" | "BROKEN" | "ALL";
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<ProductsResult> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/catalogs/search/{catalogId}/products",
      path: {
        catalogId: catalogId,
      },
      query: {
        targetSrn: targetSrn,
        locode: locode,
        page: page,
        size: size,
        imageQualityFilter: imageQualityFilter,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * Full text product search
   * Doing a fulltext search on product title, description, category, supplier article code, where availability
   * parameters are mandatory (supplierCatalogId, locode, fleetSrn list). Before it searches for anything it
   * replaces all special characters with whitespace, then use each of the terms as keywords.
   * Depending on which field it is either searched as a keyword or full text indexed string with synonyms.
   *
   * @returns ProductSearchResult OK
   * @throws ApiError
   */
  public static supplierQuery(
    {
      catalogId,
      locode,
      targetSrn,
      caller,
      sortField = "_score",
      sortOrder = "desc",
      page,
      size,
      search,
    }: {
      /**
       * Particular catalog ID. Mandatory parameter
       */
      catalogId: string;
      /**
       * Port UN Locode
       */
      locode?: string;
      /**
       * Srn of a target for the price. Usually it is AgreementSrn
       */
      targetSrn?: string;
      /**
       * Who called the search service
       */
      caller?: string;
      /**
       * field to sort on
       */
      sortField?: string;
      /**
       * direction of sort - asc/desc
       */
      sortOrder?: "asc" | "desc";
      /**
       * page number
       */
      page?: any;
      /**
       * page size
       */
      size?: any;
      /**
       * Searched phrase
       */
      search?: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<ProductSearchResult> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/catalogs/search/supplier-query",
      query: {
        catalogId: catalogId,
        locode: locode,
        targetSrn: targetSrn,
        Caller: caller,
        sortField: sortField,
        sortOrder: sortOrder,
        page: page,
        size: size,
        search: search,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * List products
   * Get a complete list of available products in a category
   * @returns ProductSearchResult OK
   * @throws ApiError
   */
  public static getProductsByCategory(
    {
      curatedCatalogId,
      locode,
      targetSrn,
      srn,
      pageable,
      supplierId,
      s2SApiVersion,
    }: {
      curatedCatalogId: string;
      locode: string;
      targetSrn: string;
      srn: string;
      pageable: Pageable;
      supplierId?: string;
      s2SApiVersion?: "3";
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<ProductSearchResult> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/catalogs/search/curated/{curatedCatalogId}/category",
      path: {
        curatedCatalogId: curatedCatalogId,
      },
      query: {
        locode: locode,
        targetSrn: targetSrn,
        srn: srn,
        supplierId: supplierId,
        pageable: pageable,
      },
      headers: {
        "S2S-API-Version": s2SApiVersion,
      },
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * Full text product search
   * Doing a fulltext search on product title, description, category, supplier article code, where availability
   * parameters are mandatory (supplierCatalogId, locode, fleetSrn list). Before it searches for anything it
   * replaces all special characters with whitespace, then use each of the terms as keywords.
   * Depending on which field it is either searched as a keyword or full text indexed string with synonyms.
   *
   * @returns ProductSearchResult OK
   * @throws ApiError
   */
  public static buyerQuery(
    {
      curatedCatalogId,
      locode,
      targetSrn,
      search,
      caller,
      sortField = "_score",
      sortOrder = "desc",
      page,
      size,
    }: {
      /**
       * Particular Curated catalog ID. Mandatory parameter
       */
      curatedCatalogId: string;
      /**
       * Port UN Locode
       */
      locode: string;
      /**
       * Srn of a target for the price. Usually it is AgreementSrn
       */
      targetSrn: string;
      /**
       * Searched phrase
       */
      search: string;
      /**
       * Who called the search service
       */
      caller?: string;
      /**
       * field to sort on
       */
      sortField?: string;
      /**
       * direction of sort - asc/desc
       */
      sortOrder?: "asc" | "desc";
      /**
       * page number
       */
      page?: any;
      /**
       * page size
       */
      size?: any;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<ProductSearchResult> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/catalogs/search/buyer-query",
      query: {
        curatedCatalogId: curatedCatalogId,
        locode: locode,
        targetSrn: targetSrn,
        Caller: caller,
        sortField: sortField,
        sortOrder: sortOrder,
        page: page,
        size: size,
        search: search,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }
}
