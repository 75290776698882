import { ImageQualityFilter } from "src/pages/CatalogsPage/types";

export const getImageFilterQuery = (imageQualityFilter: ImageQualityFilter): string => {
  switch (imageQualityFilter) {
    case "EXISTING":
      return "image:true";
    case "MISSING":
      return "image:false";
    case "BROKEN":
      return "brokenImage:true";
    case "ALL":
    default:
      return "";
  }
};
