import { useQuery } from "@tanstack/react-query";

import { ProductResponse, SearchService } from "@web/sherlock";

export const PRODUCT_DETAILS_QUERY_KEY_BASE = "productDetails";

export const useProductDetailsQuery = (catalogId: string, productId: string, accountId: string) =>
  useQuery<ProductResponse[]>({
    queryKey: [PRODUCT_DETAILS_QUERY_KEY_BASE, catalogId, productId],
    queryFn: async () => {
      const headers = {
        Accept: "*/*",
      };
      const options = {
        headers: headers,
      };
      const response = await SearchService.products(
        {
          requestBody: {
            catalog: `srn:catalog:${accountId}:catalog/${catalogId}`,
            query: `productId: ${productId}`,
          },
        },
        options
      );

      return response.products || [];
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
